class EUMemberCode {
  countryName: string;
  countryCode: string;
  VAT: number;
  accountService: number;
  accountPhysical: number;
}

export class VatCalculations {
  // How to set the account codes: https://support.fortnox.se/ordlista-bokforing/oss-one-stop-shop
  private static euMemberCodes: EUMemberCode[] = [
    {
      countryName: 'Österike',
      countryCode: 'AT',
      VAT: 20,
      accountService: 3110,
      accountPhysical: 0,
    },
    {
      countryName: 'Belgien',
      countryCode: 'BE',
      VAT: 21,
      accountService: 3111,
      accountPhysical: 0,
    },
    {
      countryName: 'Bulgarien',
      countryCode: 'BG',
      VAT: 20,
      accountService: 3112,
      accountPhysical: 0,
    },
    {
      countryName: 'Cypern',
      countryCode: 'CY',
      VAT: 19,
      accountService: 3113,
      accountPhysical: 0,
    },
    {
      countryName: 'Tjeckien',
      countryCode: 'CZ',
      VAT: 21,
      accountService: 3114,
      accountPhysical: 0,
    },
    {
      countryName: 'Tyskland',
      countryCode: 'DE',
      VAT: 19,
      accountService: 3115,
      accountPhysical: 0,
    },
    {
      countryName: 'Danmark',
      countryCode: 'DK',
      VAT: 25,
      accountService: 3116,
      accountPhysical: 0,
    },
    {
      countryName: 'Estland',
      countryCode: 'EE',
      VAT: 20,
      accountService: 3117,
      accountPhysical: 0,
    },
    {
      countryName: 'Grekland',
      countryCode: 'EL',
      VAT: 24,
      accountService: 3118,
      accountPhysical: 0,
    },
    {
      countryName: 'Spanien',
      countryCode: 'ES',
      VAT: 21,
      accountService: 3119,
      accountPhysical: 0,
    },
    {
      countryName: 'Finland',
      countryCode: 'FI',
      VAT: 24,
      accountService: 3120,
      accountPhysical: 0,
    },
    {
      countryName: 'Frankrike',
      countryCode: 'FR',
      VAT: 20,
      accountService: 3121,
      accountPhysical: 0,
    },
    {
      countryName: 'Kroatien',
      countryCode: 'HR',
      VAT: 25,
      accountService: 3122,
      accountPhysical: 0,
    },
    {
      countryName: 'Ungern',
      countryCode: 'HU',
      VAT: 27,
      accountService: 3123,
      accountPhysical: 0,
    },
    {
      countryName: 'Irland',
      countryCode: 'IE',
      VAT: 23,
      accountService: 3124,
      accountPhysical: 0,
    },
    {
      countryName: 'Italien',
      countryCode: 'IT',
      VAT: 22,
      accountService: 3125,
      accountPhysical: 0,
    },
    {
      countryName: 'Litauen',
      countryCode: 'LT',
      VAT: 21,
      accountService: 3126,
      accountPhysical: 0,
    },
    {
      countryName: 'Luxemburg',
      countryCode: 'LU',
      VAT: 17,
      accountService: 3127,
      accountPhysical: 0,
    },
    {
      countryName: 'Lettland',
      countryCode: 'LV',
      VAT: 21,
      accountService: 3128,
      accountPhysical: 0,
    },
    {
      countryName: 'Malta',
      countryCode: 'MT',
      VAT: 18,
      accountService: 3129,
      accountPhysical: 0,
    },
    {
      countryName: 'Nederländerna',
      countryCode: 'NL',
      VAT: 21,
      accountService: 3130,
      accountPhysical: 0,
    },
    {
      countryName: 'Polen',
      countryCode: 'PL',
      VAT: 23,
      accountService: 3131,
      accountPhysical: 0,
    },
    {
      countryName: 'Portugal',
      countryCode: 'PT',
      VAT: 23,
      accountService: 3132,
      accountPhysical: 0,
    },
    {
      countryName: 'Rumänien',
      countryCode: 'RO',
      VAT: 19,
      accountService: 3133,
      accountPhysical: 0,
    },
    {
      countryName: 'Sverige',
      countryCode: 'SE',
      VAT: 25,
      accountService: 3001,
      accountPhysical: 0,
    },
    {
      countryName: 'Slovenien',
      countryCode: 'SI',
      VAT: 22,
      accountService: 3134,
      accountPhysical: 0,
    },
    {
      countryName: 'Slovakien',
      countryCode: 'SK',
      VAT: 20,
      accountService: 3135,
      accountPhysical: 0,
    },
  ];

  /**
   * Returns Fortnox VAT Code for customar object
   */
  static FortnoxVATCode = (countryCode: string, business: boolean): string => {
    // Update list based on data from here: https://europa.eu/youreurope/business/taxation/vat/vat-rules-rates/index_sv.htm
    if (countryCode == 'SE') return 'SEVAT';
    let foundEUMemeber = this.euMemberCodes.find(
      (code) => code.countryCode === countryCode
    );
    if (foundEUMemeber) {
      //Fixed to VAT until we reach 90K in EU sales!
      return business ? 'EUREVERSEDVAT' : 'EUVAT';
    } else {
      return 'EXPORT';
    }
  };

  /**
   * Returns VAT amoute based on country code.
   */
  static VatBasedOnCountryCode = (countryCode: string): number => {
    // Update list based on data from here: https://europa.eu/youreurope/business/taxation/vat/vat-rules-rates/index_sv.htm
    let foundEUMemeber = this.euMemberCodes.find(
      (code) => code.countryCode === countryCode
    );
    if (foundEUMemeber) {
      //Fixed to 25% VAT until we reach 90K in EU sales!
      return 25;
    } else {
      return 0;
    }
  };

  /**
   * Returns sale account to use when selling a miniature to the buyer.
   */
  static SaleAccountServiceBasedOnCountryCode = (
    countryCode: string,
    sellerIsBusiness: boolean
  ): number => {
    // Update list based on data from here: https://europa.eu/youreurope/business/taxation/vat/vat-rules-rates/index_sv.htm
    let foundEUMemeber = this.euMemberCodes.find(
      (code) => code.countryCode === countryCode
    );
    if (foundEUMemeber) {
      //Fixed to SE VAT until we reach 90K in EU sales! then change to accountService in euMemberCodes
      if (countryCode == 'SE') return 3001; //SE sale
      else return sellerIsBusiness ? 3308 : 3001; // EU sale
    } else {
      return 3305; // Outside EU sale
    }
  };

  /**
   * Returns accounts to use when creating a purchase note from the designer.
   */
  static PurchaseAccountsBasedOnCountryCode = (
    countryCode: string,
    sellerIsBusiness: boolean
  ): {
    debtAccount: number;
    purchaseAccount: number;
    VATAccount: number;
    reversedVat: number;
  } => {
    // Update list based on data from here: https://europa.eu/youreurope/business/taxation/vat/vat-rules-rates/index_sv.htm
    let foundEUMemeber = this.euMemberCodes.find(
      (code) => code.countryCode === countryCode
    );
    if (foundEUMemeber) {
      //Fixed to SE VAT until we reach 90K in EU sales!
      if (countryCode == 'SE')
        return {
          debtAccount: 2440,
          purchaseAccount: 4010, // EU sale //TODO: Add 4010 account in Fortnox! "Inköp material och varor SE privat"
          VATAccount: sellerIsBusiness ? 2641 : null,
          reversedVat: null,
        };
      // EU sale (not Sweden)
      else
        return {
          debtAccount: 2440,
          purchaseAccount: sellerIsBusiness ? 4535 : 4011, //TODO: Add 4011 account in Fortnox! "Inköp material och varor EU privat"
          VATAccount: sellerIsBusiness ? 2647 : null,
          reversedVat: sellerIsBusiness ? 2614 : null,
        };
    } else {
      // Non EU seller
      return {
        debtAccount: 2440,
        purchaseAccount: 4012, //TODO: Add 4012 account in Fortnox! "Varuinköp, import"
        VATAccount: null,
        reversedVat: null,
      };
    }
  };
}
