import { BestDiscountForCurrentProductResult } from '../api/discount/best-discount-for-current-product';
import { ProductImage } from '../api/products/ProductImages';

export class CartItem {
	category: string;
	id: number;
	images: ProductImage[] | string;
	name: string;
	price: string;
	priceCommercial: string;
	commercialLicens: boolean;
	itemType: ItemType;
	discount?: BestDiscountForCurrentProductResult;
}

export enum ItemType {
	digitalFile = 0,
	digitalCollection = 1,
}

export class StoredCartItem {
	id: number;
	type: ItemType;
	commercialLicens: boolean;
}

export class CollectionApiDto {
	id: number;
	imageUrl: string;
	sellPriceUsd: number;
	sellPriceCommercialUsd: number;
	title: string;
}
