import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.css';
import { SessionProvider, signIn, useSession } from 'next-auth/react';
import Head from 'next/head';
import Link from 'next/link';
import Script from 'next/script';
import React, { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { Context } from '../components/context-provider';
import styles from './_app.module.css';
import Footer from './components/footer';
import Header from './components/header';

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
	const baseCookieSettings = {
		acceptedBanner: false,
		strictlyNecessaryCookies: {
			displayName: 'Strictly Necessary Cookies',
			info: 'Used for handling login sessions and souch',
			required: true,
			accepted: true,
		},
		functionalCookies: {
			displayName: 'Functional Cookies',
			info: 'Helps presenting better suggestions of product on this page',
			required: false,
			accepted: true,
		},
		performanceCookies: {
			displayName: 'Performance Cookies',
			info: 'Helps us improve the website with anonymous inputs from visits of this page',
			required: false,
			accepted: true,
		},
		targetingCookies: {
			displayName: 'Targeting Cookies',
			info: 'Helps us understand how to improve advertising to help this page grow',
			required: false,
			accepted: true,
		},
	};

	const [cart, setCart] = React.useState([]);
	const [showCookieBanner, setShowCookieBanner] = React.useState(true);
	const [showCookieSettings, setShowCookieSettings] = React.useState(false);
	const [cookieSettings, setCookieSettings] =
		React.useState(baseCookieSettings);

	useEffect(() => {
		typeof document !== undefined
			? require('bootstrap/dist/js/bootstrap')
			: null;

		//Load cart items from localStorage
		let cartArray = JSON.parse(localStorage.getItem('cart'));
		if (cartArray?.length > 0) {
			setCart(cartArray);
		}

		// Load Cookie settings
		const cookieString = localStorage.getItem('CookieSettings');
		if (cookieString) {
			const json = JSON.parse(cookieString);
			setCookieSettings(() => json);

			if (json?.acceptedBanner) {
				setShowCookieBanner(false);

				//Start up the allready accepted cookies
				if (json.performanceCookies?.accepted) {
					// Implement Hotjar event tracking
					// https://medium.com/@romansorin/using-hotjar-and-google-analytics-with-next-js-to-track-behavior-a2283d659767
					// Numbers are from installation instructions on hotjar page, hjid & hjsv
					hotjar.initialize(2969941, 6);
				}
			}
		}
	}, []);

	function acceptCurrentCookieSettings() {
		localStorage.setItem('CookieSettings', JSON.stringify(baseCookieSettings));

		setCookieSettings((oldVal) => {
			oldVal.acceptedBanner = true;
			localStorage.setItem('CookieSettings', JSON.stringify(oldVal));

			if (oldVal.performanceCookies?.accepted) {
				// Implement Hotjar event tracking
				// https://medium.com/@romansorin/using-hotjar-and-google-analytics-with-next-js-to-track-behavior-a2283d659767
				// Numbers are from installation instructions on hotjar page, hjid & hjsv
				hotjar.initialize(2969941, 6);
			}

			return oldVal;
		});
		setShowCookieBanner(false);
	}

	function displayCookieSettings() {
		localStorage.setItem('CookieSettings', JSON.stringify(baseCookieSettings));
		setShowCookieSettings(true);
	}

	function toggleCookieSetting(key, valToSet) {
		return setCookieSettings((oldVal) => {
			oldVal[key].accepted = valToSet;
			console.log(key, oldVal[key].accepted);
			localStorage.setItem('CookieSettings', JSON.stringify(oldVal));
			return { ...oldVal };
		});
	}

	return (
		<>
			<Head>
				<meta name='viewport' content='width=device-width, initial-scale=1' />
				<meta
					name='facebook-domain-verification'
					content='uhb5wiunfekrtv8abv88e382rk9myq'
				/>
			</Head>

			<Context.Provider value={[cart, setCart]}>
				<SessionProvider session={!showCookieBanner ? null : pageProps.session}>
					<Header></Header>
					<section style={{ position: 'relative' }}>
						{Component.devOnly || Component.authSellerOnly ? (
							Component.authSellerOnly ? (
								// Seller or admin role needed
								<AuthSellerOnly>
									<Component {...pageProps} />
								</AuthSellerOnly>
							) : (
								// Admin role needed
								<AuthDevOnly>
									<Component {...pageProps} />
								</AuthDevOnly>
							)
						) : Component.auth ? (
							// Login needed
							<Auth>
								<Component {...pageProps} />
							</Auth>
						) : (
							//All can access
							<Component {...pageProps} />
						)}
					</section>
					<Footer></Footer>

					{cookieSettings.targetingCookies?.accepted ? (
						<>
							<Script
								src='https://www.googletagmanager.com/gtag/js?id=G-8ZYGQV5H7D'
								strategy='afterInteractive'
							/>
							<Script id='google-analytics' strategy='afterInteractive'>
								{`
								window.dataLayer = window.dataLayer || [];
								function gtag(){window.dataLayer.push(arguments);}
								gtag('js', new Date());

								gtag('config', 'G-8ZYGQV5H7D');
								`}
							</Script>
						</>
					) : (
						<></>
					)}

					{showCookieBanner ? (
						<section className={styles.cookieArea}>
							<section className={styles.cookieBanner + ' row'}>
								<div className={styles.textArea + ' col-lg-6'}>
									<span style={{ fontSize: 40 }}>
										Co
										<i
											className={
												styles.cookieLetter + ' fa-solid fa-cookie-bite'
											}
										></i>
										kies
									</span>
									<div className={'container'}>
										<p className={styles.longDescription}>
											To improve and help build a better experience for you, we
											are using cookies. If you continue to browse the website
											without changing the settings you are accepting the
											standard use of cookies. You can read more about our
											cookies{' '}
											<Link href={`/information/privacy-policy`} passHref>
												<a>here!</a>
											</Link>
										</p>
									</div>
								</div>

								<div className={styles.buttonArea + ' col-lg-6'}>
									{!showCookieSettings ? (
										<button
											className={styles.settingsButton + ' btn btn-sm'}
											onClick={() => displayCookieSettings()}
										>
											<i className='fa-solid fa-gear'></i> Custom settings
										</button>
									) : (
										<></>
									)}

									<button
										className={styles.acceptButton + ' btn btn-sm'}
										onClick={() => acceptCurrentCookieSettings()}
									>
										Keep going!{' '}
										<i
											className={
												styles.acceptButtonIcon + ' fa-solid fa-cake-candles'
											}
										></i>
									</button>
								</div>

								{showCookieSettings ? (
									<section className={styles.customSettings}>
										<h4>
											<i className='fa-solid fa-wrench'></i> Custom Cookie
											Settings
										</h4>
										<div className={'container'}>
											<div className={'row justify-content-lg-center'}>
												<p className={styles.longDescription + ' col-lg-8 '}>
													Here you can customize your cookie experience. All
													cookies are listed below, the required ones can not be
													changed such as the login cookie. Without this website
													will not run. The remaining are used for improving the
													experience for you, but are not necessary.
												</p>
											</div>
										</div>
										{Object.keys(cookieSettings).map((key, index) => {
											if (key !== 'acceptedBanner') {
												// If the cookie is required, prevent switch to be used
												if (cookieSettings[key].required) {
													return (
														<div
															key={`switch_webpageinfo-${index}`}
															className={
																styles.switchDisabled +
																' form-check form-switch m-2'
															}
															title={cookieSettings[key].info}
														>
															<input
																className={' form-check-input'}
																type='checkbox'
																role='switch'
																id='switch_webpageinfo'
																checked={true}
																disabled={true}
															/>
															<label className='form-check-label'>
																{cookieSettings[key].displayName}
															</label>
														</div>
													);
												}

												return (
													<div
														key={`switch_webpageinfo-${index}`}
														className={
															styles.switch + ' form-check form-switch m-2'
														}
														title={cookieSettings[key].info}
													>
														<input
															className={styles.switch + ' form-check-input'}
															type='checkbox'
															role='switch'
															id={`switch_webpageinfo-${index}`}
															onChange={() => {
																toggleCookieSetting(
																	key,
																	!cookieSettings[key].accepted
																);
															}}
															checked={cookieSettings[key].accepted}
														/>
														<label className='form-check-label'>
															{cookieSettings[key].displayName}
														</label>
													</div>
												);
											}
										})}
									</section>
								) : (
									<></>
								)}
							</section>
						</section>
					) : (
						<></>
					)}
				</SessionProvider>
			</Context.Provider>
		</>
	);
}

function AuthSellerOnly({ children }) {
	const { data: session, status } = useSession();
	const isUser = !!session?.user;
	React.useEffect(() => {
		if (status === 'loading') return;
		if (!isUser) signIn();
	}, [isUser, status]);

	if (isUser) {
		// Check if user has admin role or betatester, else do not load component!
		if (!['btna', 'betatester', 'admin'].includes(session?.role)) {
			return <p>Whops</p>;
		}
		return children;
	}

	// Session is being fetched, or no user.
	// If no user, useEffect() will redirect.
	return <div>Loading...</div>;
}

function AuthDevOnly({ children }) {
	const { data: session, status } = useSession();
	const isUser = !!session?.user;
	React.useEffect(() => {
		if (status === 'loading') return;
		if (!isUser) signIn();
	}, [isUser, status]);

	if (isUser) {
		// Check if user has admin role, else do not load component!
		if (session?.role != 'admin') {
			return <p>Whops</p>;
		}
		return children;
	}

	// Session is being fetched, or no user.
	// If no user, useEffect() will redirect.
	return <div>Loading...</div>;
}

function Auth({ children }) {
	const { data: session, status } = useSession();
	const isUser = !!session?.user;
	React.useEffect(() => {
		if (status === 'loading') return;
		if (!isUser) signIn();
	}, [isUser, status]);

	if (isUser) {
		return children;
	}

	// Session is being fetched, or no user.
	// If no user, useEffect() will redirect.
	return <div>Loading...</div>;
}

export default MyApp;
