import { signIn, signOut, useSession } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { Context } from '../../components/context-provider';
import CartInfo from '../products/cart';
import styles from './header.module.css';

class menuItem {
	active: boolean = false;
	title: string;
	url: string;
	visible: boolean;
	subItems?: menuItem[];
}

function Cart(props: { cartItem: any }) {
	return (
		<section>
			<div
				className='offcanvas offcanvas-end'
				tabIndex={-1}
				id='offcanvasExample'
				aria-labelledby='offcanvasExampleLabel'
			>
				<div
					className='offcanvas-header'
					style={{
						paddingTop: 0,
						paddingBottom: 0,
						background: '#7e0f23',
						margin: '0px, 0px',
					}}
				>
					<h5
						className='offcanvas-title'
						style={{
							color: 'white',
							fontSize: 20,
							padding: 10,
							textAlign: 'center',
							fontFamily: `'titillium', sans-serif`,
						}}
						id='offcanvasExampleLabel'
					>
						<i className='fa-solid fa-cart-shopping'></i> Cart
					</h5>
					<button
						type='button'
						className='btn-close text-reset'
						data-bs-dismiss='offcanvas'
						aria-label='Close'
					></button>
				</div>
				<div className='offcanvas-body' style={{ padding: 0 }}>
					<CartInfo></CartInfo>
				</div>
			</div>
		</section>
	);
}

export default function Header() {
	const [cart, setCart] = useContext(Context);
	const { data: session } = useSession();

	const router = useRouter();
	const mainMenu: menuItem[] = [
		{
			active: router.query.category == 'stl',
			title: 'STL Miniatures',
			url: '/products/stl',
			visible: true,
			subItems: [
				{
					active: router.query.subcategory == 'Bases',
					title: 'Bases',
					url: '/products/stl/Bases',
					visible: true,
				},
				{
					active: router.query.subcategory == 'Environment',
					title: 'Environment',
					url: '/products/stl/Environment',
					visible: true,
				},
				{
					active: router.query.subcategory == 'Fantasy',
					title: 'Fantasy',
					url: '/products/stl/Fantasy',
					visible: true,
				},
				{
					active: router.query.subcategory == 'SciFi',
					title: 'SciFi',
					url: '/products/stl/SciFi',
					visible: true,
				},
				{
					active: router.query.subcategory == 'Warfare',
					title: 'Warfare',
					url: '/products/stl/Warfare',
					visible: true,
				},
			],
		},
		{
			active: router.query.category == 'Resin',
			title: 'Resin Miniatures',
			url: '/products/Resin',
			visible: session && session.role == 'admin',
		},
		{
			active: router.query.category == 'Tutorials',
			title: 'Tutorials',
			url: '/tutorials/',
			visible: session && session.role == 'admin',
		},
		{
			active: router.query.category == 'Apparel',
			title: 'Apparel',
			url: '/products/Apparel',
			visible: session && session.role == 'admin',
		},
		{
			active: router.query.category == 'GameRules',
			title: 'Game Rules',
			url: '/GameRules/',
			visible: session && session.role == 'admin',
		},
		{
			active: router.query.category == 'VideoCourses',
			title: 'Video Courses',
			url: '/VideoCourses/',
			visible: session && session.role == 'admin',
		},
		{
			active: router.query.category == 'News',
			title: 'News',
			url: '/news/',
			visible: session && session.role == 'admin',
		},
	];

	const dropDownMenu = [
		{
			sectionName: 'Customer',
			visible: true,
			links: [
				{
					name: 'My Collection',
					url: '/user-page/my-collection',
					icon: 'fa-solid fa-chess',
					visible: true,
				},
				{
					name: 'Orders',
					url: '/user-page/orders',
					icon: 'fa-solid fa-receipt',
					visible: true,
				},
			],
		},
		{
			sectionName: 'Admin',
			visible: session && session.role == 'admin',
			links: [
				{
					name: 'News',
					url: '/user-page/admin-tools/edit-news',
					icon: 'fa-solid fa-newspaper',
					visible: true,
				},
				{
					name: 'Review Products',
					url: '/user-page/admin-tools/review',
					icon: 'fa-solid fa-magnifying-glass',
					visible: true,
				},
				{
					name: 'My Tutorials',
					url: '/user-page/tutorials',
					icon: 'fa-solid fa-pen-nib',
					visible: true,
				},
			],
		},
		{
			sectionName: 'Desiger',
			visible:
				session &&
				(session.role == 'admin' ||
					session.role == 'designer' ||
					session.role == 'betatester' ||
					session.role == 'btna'),
			links: [
				{
					name: 'Open Store',
					url: '/user-page/designer/open-store',
					icon: 'fa-solid fa-store',
					visible: session && session.role == 'btna',
				},
				{
					name: 'My Designs',
					url: '/user-page/designer/products',
					icon: 'fa-solid fa-cube',
					visible:
						session &&
						(session.role == 'admin' ||
							session.role == 'designer' ||
							session.role == 'betatester'),
				},
				{
					name: 'Collections',
					url: '/user-page/designer/collections',
					icon: 'fa-solid fa-cubes',
					visible:
						session &&
						(session.role == 'admin' ||
							session.role == 'designer' ||
							session.role == 'betatester'),
				},
				{
					name: 'Discounts',
					url: '/user-page/designer/discounts',
					icon: 'fa-regular fa-percent',
					visible:
						session &&
						(session.role == 'admin' ||
							session.role == 'designer' ||
							session.role == 'betatester'),
				},
				{
					name: 'Sales',
					url: '/user-page/designer/sales',
					icon: 'fa-solid fa-money-bill-1-wave',
					visible:
						session &&
						(session.role == 'admin' ||
							session.role == 'designer' ||
							session.role == 'betatester'),
				},
				,
				{
					name: 'Statistics',
					url: '/user-page/designer/statistics',
					icon: 'fa-solid fa-chart-line',
					visible:
						session &&
						(session.role == 'admin' ||
							session.role == 'designer' ||
							session.role == 'betatester'),
				},
			],
		},
		{
			sectionName: 'Account',
			visible: true,
			links: [
				{
					name: 'Settings',
					url: '/user-page/settings/profile-settings',
					icon: 'fa-solid fa-gears',
					visible: true,
				},
			],
		},
	];

	return (
		<>
			<header className={styles.header}>
				<div
					className={styles.colorStripe}
					style={{ height: 80, position: 'relative' }}
				>
					<section style={{ maxWidth: 1000, margin: 'auto' }}>
						<Logo />
					</section>
				</div>
				<section style={{ display: 'relative', zIndex: 30 }}>
					<LoginRegisterAndUserMenu />
					<MainMenu />
					{/* <SubMenu /> */}
				</section>
				<Cart cartItem={cart}></Cart>
				<div style={{ height: 3, background: '#7e0f23' }}></div>
			</header>
		</>
	);

	function MainMenu() {
		return (
			<section
				style={{
					boxShadow: '#00000069 0px 0px 15px',
					background: '#f8f9fa',
					position: 'relative',
					zIndex: 2,
				}}
			>
				<section className={styles.row}>
					<menu
						className={styles.categoryList}
						style={{ display: 'inline-block' }}
					>
						<ul
							className={styles.menu}
							style={{
								display: 'inline-block',
							}}
						>
							{mainMenu.map((mainMenuItem, index) => {
								return mainMenuItem.visible ? (
									<li
										key={`MainMenuItem-${index}`}
										className={styles.menuItem}
										style={{
											border: 'none',
											outline: 'none',
											background: '#f8f9fa',
										}}
									>
										<Link href={mainMenuItem.url} passHref>
											<a>
												<button
													className='btn btn-sm btn-outline-secondary'
													style={{ border: 'none', outline: 'none' }}
													type='button'
												>
													{mainMenuItem.title}
												</button>
											</a>
										</Link>
									</li>
								) : (
									''
								);
							})}
						</ul>
					</menu>
				</section>
			</section>
		);
	}

	function LoginRegisterAndUserMenu() {
		return (
			<section
				style={{
					position: 'relative',
					zIndex: 30,
					background: '#f8f9fa',
					borderColor: '#f8f9fa',
				}}
			>
				<section className={styles.row}>
					<menu
						className={styles.categoryList}
						style={{ display: 'inline-block', float: 'right', padding: 0 }}
					>
						<button
							className='btn'
							type='button'
							data-bs-toggle='offcanvas'
							data-bs-target='#offcanvasExample'
							aria-controls='offcanvasExample'
							style={{
								cursor: 'pointer',
								display: 'inline-block',
								paddingTop: 4,
								marginRight: 10,
								marginBottom: 0,
								color: '#7e0f23',
								height: 33,
								borderRadius: 0,
							}}
						>
							<i className='fa-solid fa-cart-shopping'></i>
							{` ${cart.length} items `}
						</button>

						<ul
							style={{
								display: 'inline-block',
								padding: 0,
							}}
						>
							{session ? (
								<li className={styles.userSignInOrActive}>
									<div className='dropdown' id='user-dropdown'>
										<div style={{ display: 'flex' }}>
											<button
												className='btn btn-light btn-sm dropdown-toggle'
												type='button'
												id='dropdownMenuButton1'
												data-bs-toggle='dropdown'
												aria-expanded='false'
												style={{
													borderRadius: 0,
													height: 33,
													background: '#f8f9fa',
													borderColor: '#f8f9fa',
												}}
											>
												{session.user.name}
											</button>
											<div
												style={{
													position: 'relative',
													height: 33,
													width: 33,
													display: 'inline-block',
												}}
											>
												<Image
													src={session.user.image}
													alt={`Profile picture of ${session.user.name}`}
													style={{ aspectRatio: '1/1' }}
													layout='fill'
													objectFit='cover'
													objectPosition='bottom'
												/>
											</div>

											<ul
												className='dropdown-menu'
												aria-labelledby='dropdownMenuButton1'
												style={{
													borderRadius: 0,
													borderBottomLeftRadius: '0.5rem',
													borderBottomRightRadius: '0.5rem',
													marginTop: -1,
													width: 230,
												}}
											>
												<li style={{ width: '100%' }}>
													<h6 className='dropdown-header'>
														{session.user.name}
													</h6>

													<Link href={`/user-page/${session.user.name}`}>
														<a className='dropdown-item'>
															<i
																className='fa-solid fa-house'
																style={{ width: 25 }}
															></i>{' '}
															My Page
														</a>
													</Link>
												</li>

												{dropDownMenu.map((dropDownSection, i) =>
													dropDownSection.visible ? (
														<div key={`dropDownSection-${i}`}>
															<li style={{ width: '100%' }}>
																<hr className='dropdown-divider' />
																<h6 className='dropdown-header'>
																	{dropDownSection.sectionName}
																</h6>
															</li>

															{dropDownSection.links.map((sectionItem, i) =>
																sectionItem.visible ? (
																	<li
																		style={{ width: '100%' }}
																		key={`dropDownSection-${i}`}
																	>
																		<Link href={sectionItem.url}>
																			<a className='dropdown-item'>
																				<i
																					className={sectionItem.icon}
																					style={{ width: 25 }}
																				></i>{' '}
																				{sectionItem.name}
																			</a>
																		</Link>
																	</li>
																) : (
																	''
																)
															)}
														</div>
													) : (
														<></>
													)
												)}

												<li style={{ width: '100%' }}>
													<hr className='dropdown-divider' />
												</li>
												<li style={{ width: '100%' }}>
													<button
														className='dropdown-item'
														onClick={() => signOut()}
													>
														<i
															className='fa-solid fa-arrow-right-from-bracket'
															style={{ width: 25 }}
														></i>
														Sign out
													</button>
												</li>
											</ul>
										</div>
									</div>
								</li>
							) : (
								<li className={styles.userSignInOrActive}>
									<button
										className='btn btn-sm btn-outline-secondary'
										type='button'
										onClick={() => signIn()}
										style={{ borderRadius: 0, border: 0, height: 33 }}
									>
										Register / Login
									</button>
								</li>
							)}
						</ul>
					</menu>
				</section>
			</section>
		);
	}

	function Logo() {
		return (
			<Link href='/' passHref>
				{/* Larger screens */}
				<a style={{ cursor: 'pointer' }}>
					<div className='d-none d-md-block'>
						<img
							src='/img/header/logo.svg'
							style={{ height: 50, width: 60, marginTop: 30 }}
							alt='logo in header of royalwargame'
						/>
						<span
							className={styles.logoText}
							style={{
								color: '#7e0f23',
								paddingLeft: 0,
								verticalAlign: 'bottom',
							}}
						>
							Royal War Game
						</span>
					</div>

					{/* Smaller screens */}
					<div className='d-md-none'>
						<span
							className={styles.logoText}
							style={{
								color: '#7e0f23',
								marginTop: 48,
								cursor: 'pointer',
							}}
						>
							Royal War Game
						</span>
					</div>
				</a>
			</Link>
		);
	}
}
