import moment from 'moment';
import Link from 'next/link';
import styles from './footer.module.css';

export default function Footer() {
  return (
    <footer
      className={styles.footer}
      style={{ position: 'relative', overflow: 'hidden' }}
    >
      <section style={{ position: 'relative', zIndex: 2 }}>
        <section className={styles.listCollection + ' row'}>
          <ul className={styles.list + ' col-md-4'}>
            <li className={styles.title}>
              <Link href={'/'}>
                <a>Royal War Game</a>
              </Link>
            </li>
            <li>
              <Link href={'/about'} passHref>
                <a>About</a>
              </Link>
            </li>
            <li>
              <Link href={'/how-it-works'} passHref>
                <a>How it Works</a>
              </Link>
            </li>
            {/* <li>Questions</li> */}
            {/* <li>Blog</li> */}
            <Link href={'/contact'} passHref>
              <li>Contact</li>
            </Link>
          </ul>
          <ul className={styles.list + ' col-md-4'}>
            <li className={styles.header}>Designers & Makers</li>
            <li>
              <Link href={'/monetize'} passHref>
                <a>Monetize / Sell</a>
              </Link>
            </li>
            {/* <li>3D Printing Tips</li> */}
            <li>
              <Link href={'/designer-tools'} passHref>
                <a>Designer Tools</a>
              </Link>
            </li>
            {/* <li>Discounts</li> */}
            {/* <li>
            <Link href={'/populare-stl-files'} passHref>
              <a>Populare STL Files</a>
            </Link>
          </li> */}
            {/* <li>Trending Searches</li> */}
          </ul>

          <ul className={styles.list + ' col-md-4'}>
            <li className={styles.header}>Partners</li>
            {/* <li>
						<Link href={'/advertise'} passHref>
							<a>Advertise with us</a>
						</Link>
					</li> */}
            <li>
              <Link href={'/hosting-competitions'} passHref>
                <a>Hosting Competitions</a>
              </Link>
            </li>
          </ul>
        </section>

        <div className={styles.lineBreak} />
        <p className={styles.paymentInfo}>
          All Payments are made safe by Credit Card via Stripe or PayPal
        </p>
        <p className={styles.bottomText}>
          Copyright KingVirrDesign, {moment().format('YYYY')} / Registerd for
          VAT in Sweden /{' '}
          <Link href={`/information/privacy-policy`} passHref>
            <a className={styles.bottomTextLink}>Privacy Policy</a>
          </Link>{' '}
          /{' '}
          <Link href={`/information/terms-and-conditions`} passHref>
            <a className={styles.bottomTextLink}>Terms & Conditions</a>
          </Link>{' '}
          {/* / <a className={styles.bottomTextLink}>Sitemap</a> */}
        </p>
      </section>
      <img
        src='/img/header/logo.svg'
        style={{
          zIndex: 1,
          position: 'absolute',
          width: 400,
          right: -80,
          bottom: 0,
          opacity: 0.3,
        }}
        alt='larger logo of royalwargame in footer'
      />
    </footer>
  );
}
