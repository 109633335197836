import moment from 'moment';
import { signIn, useSession } from 'next-auth/react';
import Head from 'next/head';
import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../../components/context-provider';
import { ProductImage } from '../../../models/api/products/ProductImages';
import {
  CartItem,
  CollectionApiDto,
  ItemType,
  StoredCartItem,
} from '../../../models/cart/cartItem';
import { IpAndLocation } from '../../../models/ip';
import { VatCalculations } from '../../../models/vat/VatCalculations';
import styles from '../../../styles/Home.module.css';
import cartStyles from './cart.module.css';

export default function CartInfo() {
  const [ip, setIP] = useState<IpAndLocation>();
  const [cart, setCart] = useContext<StoredCartItem[][]>(Context);
  const { data: session, status } = useSession();
  const [idsToQuery, setIdsToQuery] = useState('');
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [cartTotalPrice, setCartTotalPrice] = useState(0);
  const [acceptedTermsAndUse, setAcceptedTermsAndUse] = useState(false);
  const [productIds, setProductIds] = useState('');
  const [collectionIds, setCollectionIds] = useState('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    if (cart.length == 0) {
      // No items, skip to load data
      setLoading(false);
      return;
    }

    let customerIpInfo: IpAndLocation = JSON.parse(
      localStorage.getItem('customerIpInfo')
    );
    if (!customerIpInfo) {
      // Ip is not fetched yet, update locale storage
      fetch(`/api/cart/get-ip`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data: { customerIpInfo: IpAndLocation }) {
          localStorage.setItem(
            'customerIpInfo',
            JSON.stringify(data.customerIpInfo)
          );
          setIP(data.customerIpInfo);
        })
        .catch(function (error) {
          console.log('Error in Cart get-ip', error);
        });
    } else {
      setIP(customerIpInfo);
    }
  }, [cart]);

  useEffect(() => {
    if (ip == null) return;
    // The states is not set until after this useEffect, so use a tmp value to be abel to post the value to be set later
    const tmpLocalStorageCart: StoredCartItem[] = cart ?? [];
    let tmpProductIds = '';
    let tmpCollectionIds = '';

    console.log('tmpLocalStorageCart', tmpLocalStorageCart);

    for (let i = 0; i < tmpLocalStorageCart.length; i++) {
      // Add for gui

      if (tmpLocalStorageCart[i].type == ItemType.digitalFile) {
        // Check if already added
        let skip = false;
        for (const cartItem of cartItems) {
          if (
            cartItem.id == tmpLocalStorageCart[i].id &&
            cartItem.itemType == ItemType.digitalFile
          ) {
            skip = true;
          }
        }
        if (skip) continue;

        setProductIds(
          (oldVal) =>
            (oldVal += `${productIds.length == 0 ? '' : ','}${
              tmpLocalStorageCart[i].id
            }`)
        );

        // Add for useEffect calcs
        tmpProductIds += `${tmpProductIds.length == 0 ? '' : ','}${
          tmpLocalStorageCart[i].id
        }`;
      } else if (tmpLocalStorageCart[i].type == ItemType.digitalCollection) {
        // Add for gui

        // Check if already added
        let skip = false;
        for (const cartItem of cartItems) {
          if (
            cartItem.id == tmpLocalStorageCart[i].id &&
            cartItem.itemType == ItemType.digitalCollection
          ) {
            skip = true;
          }
        }
        if (skip) continue;

        setCollectionIds(
          (oldVal) =>
            (oldVal += `${collectionIds.length == 0 ? '' : ','}${
              tmpLocalStorageCart[i].id
            }`)
        );

        // Add for useEffect calcs
        tmpCollectionIds += `${tmpCollectionIds.length == 0 ? '' : ','}${
          tmpLocalStorageCart[i].id
        }`;
      } else {
        console.log(
          'whhat? unknown item type in cart...',
          tmpLocalStorageCart[i]
        );
      }
    }

    const tmpIdsToQuery = tmpLocalStorageCart
      .toString()
      .replace('[', '')
      .replace(']', '');
    setIdsToQuery(tmpIdsToQuery);

    // If there is nothing to query, ignore api call
    if (tmpIdsToQuery.length == 0) {
      return;
    }

    // Get info for each product
    if (tmpProductIds.length > 0) {
      console.log('tmpProductIds', tmpProductIds);
      fetch(`/api/cart/get-all-products-by-ids`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ids: tmpProductIds,
        }),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          data.products.map((row: CartItem) => {
            //find matching cart item to get if commercialLicens or regular
            let productIndex = tmpLocalStorageCart.findIndex(
              (product) =>
                product.id == row.id && product.type == ItemType.digitalFile
            );

            let commercialLicens = false;
            if (productIndex != -1)
              commercialLicens =
                tmpLocalStorageCart[productIndex].commercialLicens;

            setCartTotalPrice((oldVal) => {
              if (commercialLicens) {
                if (row.discount) {
                  return oldVal + row.discount.discountedPriceCommercial;
                }
                return oldVal + parseFloat(row.priceCommercial);
              } else {
                if (row.discount) {
                  return oldVal + row.discount.discountedPrice;
                }
                return oldVal + parseFloat(row.price);
              }
            });

            setCartItems((oldVal) => [
              ...oldVal,
              {
                id: row.id,
                name: row.name,
                category: row.category,
                images: (row.images[0] as ProductImage)?.name,
                price: row.price.toString(),
                priceCommercial: row.priceCommercial?.toString(),
                commercialLicens: commercialLicens,
                itemType: ItemType.digitalFile,
                discount: row.discount,
              },
            ]);
          });
        })
        .catch(function (error) {
          console.log('Error in Cart get-all-products-by-ids', error);
        });
    }

    if (tmpCollectionIds.length > 0) {
      // Get info for each collection
      fetch(`/api/cart/get-all-collections-by-ids`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ids: tmpCollectionIds,
        }),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          data.collections.map((row: CollectionApiDto) => {
            //find matching cart item to get if commercialLicens or regular
            let productIndex = tmpLocalStorageCart.findIndex(
              (product) =>
                product.id == row.id &&
                product.type == ItemType.digitalCollection
            );

            let commercialLicens = false;
            if (productIndex != -1)
              commercialLicens =
                tmpLocalStorageCart[productIndex].commercialLicens;

            setCartTotalPrice((oldVal) => {
              if (commercialLicens)
                return (
                  oldVal + parseFloat(row.sellPriceCommercialUsd.toString())
                );
              else return oldVal + parseFloat(row.sellPriceUsd.toString());
            });

            setCartItems((oldVal) => [
              ...oldVal,
              {
                id: row.id,
                name: row.title,
                category: '',
                images: row.imageUrl as string,
                price: row.sellPriceUsd.toString(),
                priceCommercial: row.sellPriceCommercialUsd?.toString(),
                commercialLicens: commercialLicens,
                itemType: ItemType.digitalCollection,
                discountValue: null,
              },
            ]);
          });
        })
        .catch(function (error) {
          console.log('Error in Cart get-all-collections-by-ids', error);
        });
    }

    setLoading(false);
  }, [ip]);

  function deleteItemFromCart(index: any): void {
    let tmpLocalStorageCart: StoredCartItem[] = [];
    let newCartItems: CartItem[] = [];
    let totalPrice = 0;

    let counter = 0;
    for (const val of cartItems) {
      if (counter++ != index) {
        newCartItems.push(val);
        tmpLocalStorageCart.push({
          id: val.id,
          type: val.itemType,
          commercialLicens: val.commercialLicens,
        });
        if (val.commercialLicens) totalPrice += parseFloat(val.priceCommercial);
        else totalPrice += parseFloat(val.price);
      }
    }
    localStorage.setItem('cart', JSON.stringify(tmpLocalStorageCart));
    setCartItems(() => newCartItems);

    setCartTotalPrice(() => totalPrice);
  }

  return (
    <div className={styles.container} style={{ padding: 0 }}>
      <Head>
        <meta
          name='description'
          content='STL Miniatures and Enviroment for Table Top War Games'
        />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <main
        className={styles.main}
        style={{
          maxWidth: 600,
        }}
      >
        {loading ? (
          <div>
            <div className='d-flex justify-content-center mt-5'>
              <div
                className='spinner-grow'
                role='status'
                style={{ color: '#7e0f23' }}
              >
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
            <p style={{ width: '100%', textAlign: 'center' }}>Loading cart</p>
          </div>
        ) : (
          <table className='table'>
            <tbody>
              {cartItems.length > 0 ? (
                <>
                  {productIds.length > 0 ? (
                    <>
                      <tr>
                        <td style={{ border: 0 }}>
                          <div
                            style={{
                              transform: 'skew(-15deg)',
                              background: '#7e0f23',
                              color: 'white',
                              textAlign: 'center',
                              marginTop: 20,
                            }}
                          >
                            <p
                              style={{
                                transform: 'skew(15deg)',
                                marginBottom: 0,
                              }}
                            >
                              Single files
                            </p>
                          </div>
                        </td>
                      </tr>
                      {cartItems.map((item, index) =>
                        item.itemType == ItemType.digitalFile ? (
                          <tr key={`cart-item-${index}`}>
                            <td>
                              <img
                                style={{
                                  width: 80,
                                  height: 80,
                                  marginTop: 10,
                                  objectFit: 'cover',
                                  aspectRatio: '1/1',
                                }}
                                src={
                                  item.images !== undefined
                                    ? `${process.env.NEXT_PUBLIC_SPACES}/images/${item.id}/product-page-mini-${item.images}`
                                    : '/img/noImage/no-product-card-230x230.png'
                                }
                                alt={`product picture of ${item.name}`}
                              />
                            </td>

                            <td style={{ paddingTop: 20 }}>
                              {item.discount ? (
                                <p
                                  style={{
                                    width: '100%',
                                    marginBottom: 5,
                                    color: '#680416',
                                  }}
                                >
                                  <span
                                    style={{
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    <i
                                      className='fa-solid fa-gift'
                                      style={{ marginRight: 5 }}
                                    ></i>
                                    <b>SALE</b> -{' '}
                                    {moment(
                                      item.discount?.endDate,
                                      'YYYY-MM-DD'
                                    ).diff(moment(), 'days') + 1}{' '}
                                    {moment(
                                      item.discount?.endDate,
                                      'YYYY-MM-DD'
                                    ).diff(moment(), 'days') +
                                      1 ==
                                    1
                                      ? 'day'
                                      : 'days'}{' '}
                                    left!{' '}
                                  </span>
                                </p>
                              ) : (
                                <></>
                              )}
                              <p style={{ marginBottom: 0 }}>
                                <i
                                  className='fa-solid fa-file'
                                  title='Digital File Product'
                                ></i>{' '}
                                {item.name}
                              </p>

                              {!item.commercialLicens ? (
                                <p style={{ fontSize: 12, color: 'Gray' }}>
                                  <i className='fa-solid fa-user' /> Private
                                  license
                                </p>
                              ) : (
                                <></>
                              )}

                              {item.commercialLicens ? (
                                <p style={{ fontSize: 12, color: 'Gray' }}>
                                  <i className='fa-solid fa-store' /> Commercial
                                  license
                                </p>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td style={{ lineHeight: '100px' }}>
                              {item.commercialLicens
                                ? item.discount
                                  ? item.discount.discountedPriceCommercial
                                  : item.priceCommercial
                                : item.discount
                                ? item.discount.discountedPrice
                                : item.price}
                              $
                            </td>
                            <td
                              onClick={() => deleteItemFromCart(index)}
                              className='btn-delete'
                              style={{ lineHeight: '100px', cursor: 'pointer' }}
                            >
                              X
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {collectionIds.length > 0 ? (
                    <>
                      <tr>
                        <td style={{ border: 0 }}>
                          <div
                            style={{
                              transform: 'skew(-15deg)',
                              background: '#7e0f23',
                              color: 'white',
                              textAlign: 'center',
                              marginTop: 20,
                            }}
                          >
                            <p
                              style={{
                                transform: 'skew(15deg)',
                                marginBottom: 0,
                              }}
                            >
                              Collections
                            </p>
                          </div>
                        </td>
                      </tr>
                      {cartItems.map((item, index) =>
                        item.itemType == ItemType.digitalCollection ? (
                          <tr key={`cart-item-${index}`}>
                            <td>
                              <img
                                style={{ width: 80, paddingTop: 20 }}
                                src={
                                  item.images !== undefined
                                    ? `${process.env.NEXT_PUBLIC_SPACES}/images/${item.id}/product-page-mini-${item.images}`
                                    : '/img/noImage/no-product-card-230x230.png'
                                }
                                alt={`collection picture of ${item.name}`}
                              />
                            </td>
                            <td style={{ paddingTop: 30 }}>
                              <p style={{ marginBottom: 0 }}>
                                <i
                                  className='fa-solid fa-file'
                                  title='Digital File Product'
                                ></i>{' '}
                                {item.name}
                              </p>
                              {!item.commercialLicens ? (
                                <p style={{ fontSize: 12, color: 'Gray' }}>
                                  <i className='fa-solid fa-user' /> Private
                                  license
                                </p>
                              ) : (
                                <></>
                              )}
                              {item.commercialLicens ? (
                                <p style={{ fontSize: 12, color: 'Gray' }}>
                                  <i className='fa-solid fa-store' /> Commercial
                                  license
                                </p>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td style={{ lineHeight: '100px' }}>
                              {item.commercialLicens
                                ? item.discount
                                  ? item.discount.discountedPriceCommercial
                                  : item.priceCommercial
                                : item.discount
                                ? item.discount.discountedPrice
                                : item.price}
                              $
                            </td>
                            <td
                              onClick={() => deleteItemFromCart(index)}
                              className='btn-delete'
                              style={{ lineHeight: '100px', cursor: 'pointer' }}
                            >
                              X
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <tr>
                  <td>
                    <h3
                      style={{
                        textAlign: 'center',
                        color: '#6a6a6a',
                        paddingTop: 50,
                      }}
                    >
                      <i className='fa-solid fa-face-surprise'></i> Empty!
                    </h3>
                    <p style={{ textAlign: 'center', color: '#6a6a6a' }}>
                      Scroll through the site to find your next mini to print!
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {cartItems.length > 0 && !session ? (
          <>
            <section>
              <h4>
                <i className='fa-solid fa-hat-wizard'></i> 1. Where to store
                your purchase?
              </h4>
              <p>
                Your currently not logged in, select how you want to reach your
                minis in the future:
              </p>

              <button
                className={cartStyles.loginButton}
                type='button'
                onClick={() => signIn()}
              >
                Register / Login
              </button>
            </section>
          </>
        ) : cartItems.length > 0 ? (
          <>
            <div className='col-12'>
              <div
                className='form-check'
                style={{
                  background: acceptedTermsAndUse ? '' : '#b1e98f',
                  padding: 20,
                  width: '100%',
                }}
              >
                <input
                  className={'form-check-input'}
                  type='checkbox'
                  id='switch_webpageinfo'
                  onChange={(e) => {
                    setAcceptedTermsAndUse(e.target.checked);
                  }}
                  checked={acceptedTermsAndUse}
                  style={{ marginLeft: 1, marginRight: 20 }}
                />
                <label className='form-check-label'>
                  I agree to the{' '}
                  <Link href={`/information/terms-and-conditions`}>
                    Terms and Conditions
                  </Link>
                </label>
              </div>
            </div>
            <section className='mt-4' style={{ textAlign: 'right' }}>
              <Link
                href={
                  session && acceptedTermsAndUse
                    ? `/products/cart/checkout`
                    : '#'
                }
              >
                <a
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                  }}
                >
                  <div
                    data-bs-dismiss='offcanvas'
                    className={
                      session && idsToQuery.length > 0 && acceptedTermsAndUse
                        ? cartStyles.checkoutButton
                        : cartStyles.checkoutButtonDisabled
                    }
                    style={{ paddingBottom: 5, marginBottom: 40 }}
                  >
                    {session && acceptedTermsAndUse ? (
                      <i className='fa-solid fa-crown'></i>
                    ) : (
                      <i className='fa-solid fa-lock'></i>
                    )}{' '}
                    Checkout - Total:{' '}
                    {cartTotalPrice > 0
                      ? `${(
                          Math.ceil(
                            cartTotalPrice *
                              ((VatCalculations.VatBasedOnCountryCode(
                                ip.country_code
                              ) +
                                100) /
                                100) *
                              100
                          ) / 100
                        ).toFixed(2)} $`
                      : 'Free!'}
                    <p style={{ fontSize: '0.8em', textAlign: 'end' }}>
                      VAT:{' '}
                      {(
                        Math.ceil(
                          (cartTotalPrice *
                            ((VatCalculations.VatBasedOnCountryCode(
                              ip.country_code
                            ) +
                              100) /
                              100) -
                            cartTotalPrice) *
                            100
                        ) / 100
                      ).toFixed(2)}
                      $
                    </p>
                  </div>
                </a>
              </Link>
            </section>
          </>
        ) : (
          <></>
        )}
      </main>
    </div>
  );
}
